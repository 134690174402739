import { useRecords } from "@/stores/records";
import { computed } from "vue";
import type { ComputedRef } from "vue";

import type { StoreQueryFilter, StoreOperation, StoreQueryParams, StoreQueryOperation, StoreRecordInternals, CollectionNames, StoreQueryOptions } from "~/services/store";

import { StoreOperationTypes, pushRecords, StoreProviderTypes } from "~/services/store";

import { useOperations } from "~/stores/operations";
import { get, register } from "~/stores/tasks";
import { v4 as uuidv4 } from "uuid";
import cacheHandler from "./cacheHandler";

export default function (
	collectionName: Ref<CollectionNames>,
	filters?: Ref<StoreQueryFilter[]>,
	params?: StoreQueryParams,
	options?: Ref<StoreQueryOptions>
): { operation: ComputedRef<StoreQueryOperation | undefined>; task: ComputedRef<Promise<boolean> | undefined> } {
	const nuxtApp = useNuxtApp();
	const restapi = nuxtApp.$restapi;
	const records = useRecords();
	const operations = useOperations();
	const logger = nuxtApp.$logger;

	function listOperations(ssr: boolean) {
		return (
			operations.list
				.sort((a: StoreOperation, b: StoreOperation) => {
					return a.timestamp - b.timestamp;
				})
				.filter((item: StoreOperation) => item.type === StoreOperationTypes.query && item.ssr === ssr) as StoreQueryOperation[]
		).filter((item) => item.collectionName === collectionName.value && JSON.stringify(item.filters) === JSON.stringify(filters?.value));
	}

	// create computed reference once
	const operation = computed<StoreQueryOperation | undefined>(() => {
		return listOperations(process.server ? true : false).filter((item) => item.processing)[0];
	});

	// create computed task value
	let task = computed<Promise<boolean> | undefined>(() => {
		if (operation.value) {
			return get(operation.value.id);
		} else {
			return undefined;
		}
	});

	watch(
		[collectionName, filters, options],
		() => {
			let ssrOperations = listOperations(true);
			let clientOperations = listOperations(false);

			if (cacheHandler(ssrOperations, clientOperations, params)) return;

			// if there is an ongoing operation already
			if (operation.value) {
				return;
			}

			const timestamp = Date.now();

			const id = operations.push({
				type: StoreOperationTypes.query,
				id: uuidv4(),
				collectionName: collectionName.value,
				processing: true,
				timestamp: timestamp,
				provider: process.server ? params?.provider || StoreProviderTypes.restapi : StoreProviderTypes.restapi,
				ssr: process.server ? true : false,
				filters: filters?.value,
				count: ssrOperations.length + clientOperations.length,
			} as StoreQueryOperation);

			const task = restapi
				.getDocuments(collectionName.value, filters?.value, options?.value)
				.then(async (documents) => {
					pushRecords(records, documents, collectionName.value, { saved: true } as StoreRecordInternals);

					return true;
				})
				.catch(async (error) => {
					logger.error(error);
					operations.update(id, { error } as StoreQueryOperation);
					throw error;
				})
				.finally(() => {
					operations.update(id, { processing: false, elapsed_time: Date.now() - timestamp } as StoreQueryOperation);
				});

			register(id, task);
		},
		{ immediate: true }
	);

	return { operation, task };
}
