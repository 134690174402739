import type { ComputedRef } from "vue";
import { type StoreQueryFilter, type StoreQueryParams, type StoreQueryOperation, type CollectionNames, type StoreQueryOptions, type StoreOperation, StoreOperationTypes } from "~~/services/store";
import createQueryOperation from "./operations/createQueryOperation";
import queryRecords from "./records/queryRecords";
import { useOperations } from "~/stores/operations";

export default function <T>(
	collectionName: Ref<CollectionNames>,
	filters: Ref<StoreQueryFilter[]> = ref([]),
	params: StoreQueryParams = {},
	options: Ref<StoreQueryOptions> = ref({})
): { records: ComputedRef<T[]>; operation: ComputedRef<StoreQueryOperation | undefined>; task: ComputedRef<Promise<boolean> | undefined>; loading: ComputedRef<boolean> } {
	// Create once, this is fully reactive itself
	const records = queryRecords<T>(collectionName, filters);
	const operations = useOperations();
	// Create once, this is fully reactive itself
	const { operation, task } = createQueryOperation(collectionName, filters, params, options);

	// check if query has ever processed on server or client
	const loading = computed(() => {
		const filtered = operations.list.filter((item: StoreOperation) => item.type === StoreOperationTypes.query) as StoreQueryOperation[];

		const completed = filtered.filter((item) => item.collectionName === collectionName.value && item.processing === false && JSON.stringify(item.filters) === JSON.stringify(filters?.value));

		return completed.length === 0;
	});

	return { records, operation, task, loading };
}
