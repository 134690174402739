import { useRecords } from "~/stores/records";
import { computed } from "vue";
import type { ComputedRef, Ref } from "vue";
import { StoreQueryFilterOperators, unwrapReference } from "~/services/store";
import type { CollectionNames, StoreQueryFilter } from "~/services/store";

export default function <T>(collectionName: Ref<CollectionNames>, filters?: Ref<StoreQueryFilter[]>): ComputedRef<T[]> {
	const records = useRecords();

	return computed<T[]>(() => {
		return records.list
			.filter((item) => !item.internals.deleting)
			.filter((item) => {
				const { collectionName: internal } = unwrapReference(item.internals.reference);
				//console.log("item1", item);
				return internal === collectionName.value;
			})
			.filter((item: any) => {
				//console.log(item);
				if (!filters || !filters.value.length) {
					return true;
				}
				return filters.value.every((filter) => {
					if (filter.operator === StoreQueryFilterOperators.equal) {
						return item[filter.field] === filter.value;
					} else if (filter.operator === StoreQueryFilterOperators.notEqual) {
						return item[filter.field] !== filter.value;
					} else if (filter.operator === StoreQueryFilterOperators.less) {
						return item[filter.field] < filter.value;
					} else if (filter.operator === StoreQueryFilterOperators.lessThanOrEqual) {
						return item[filter.field] <= filter.value;
					} else if (filter.operator === StoreQueryFilterOperators.greater) {
						return item[filter.field] > filter.value;
					} else if (filter.operator === StoreQueryFilterOperators.greaterOrEqual) {
						return item[filter.field] >= filter.value;
					} else if (filter.operator === StoreQueryFilterOperators.arrayContains) {
						return item[filter.field].includes(filter.value);
					} else if (filter.operator === StoreQueryFilterOperators.arrayContainsAny) {
						return filter.value.some((value) => item[filter.field].includes(value));
					}
				});
			}) as T[];
	});
}
